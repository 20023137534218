import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import tagManager from "../utils/tag-manager"
import useHeroImage from "../hooks/useHeroImage"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import GalleryImageSection from "../components/dynamic-sections/GalleryImageSection"
import GalleryImageLargeSection from "../components/dynamic-sections/GalleryImageLargeSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import FourImageSmallSection from "../components/dynamic-sections/FourImageSmallSection"
import SixImageSection from "../components/dynamic-sections/SixImageSection"

/** static sections */
import PartnerSection from "../components/static-sections/PartnerSection"
import NewsletterSignup from "../components/static-sections/NewsletterSignupSection"

/** svg */
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const homePage: React.FC<Props> = (props) => {
  const { titleOne, subTitleOne, imageOne, twitterCardOne, twitterOpenGraphOne } = useHeroImage()

  tagManager.pushOnce({
    event: "view-home",
  })
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/",
          name: "Norhart Supply",
          image: `${config.siteMetadata.siteUrl}/home/norhart-home-hero.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Norhart Supply | Supplies For Multifamily Contractors"
      description="We Provide Building Supplies For Multifamily Contractors"
      keywords=""
      imageTwitter={`${config.siteMetadata.siteUrl}${twitterCardOne}`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}${twitterOpenGraphOne}`}
      colorPalette={colorPalette}
    >

      <HeroTopSection
        title="Construction Supplies"
        subtitle="For Your Multifamily Projects"
        imageTitle="Norhart Supply"
        image="/home/norhart-home-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="We Provide Building Supplies"
        tagLine="For Multifamily Contractors"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Supply"
        title="Multifamily Construction Supplies"
        subtitle="Let’s face it—The entire multifamily construction industry has not seen significant improvements in productivity in the last 40+ years like other industries have (e.g., manufacturing).  There are many reasons for this, but the way contractors source and purchase their supplies plays a large role in this issue.  Building material suppliers compete on availability, product selection, quality, and price.  We focus on product quality and price over availability and product selection."
        imageTitleOne="Norhart Supply Image 1"
        imageOne="/home/gallery/multifamily/norhart-supply-1.png"
        imageTitleTwo="Norhart Supply Image 2"
        imageTwo="/home/gallery/multifamily/norhart-supply-2.png"
        imageTitleThree="Norhart Supply Image 3"
        imageThree="/home/gallery/multifamily/norhart-supply-3.png"
        imageTitleFour="Norhart Supply Image 4"
        imageFour="/home/gallery/multifamily/norhart-supply-4.png"
        imageTitleFive="Norhart Supply Image 5"
        imageFive="/home/gallery/multifamily/norhart-supply-5.png"
        imageTitleSix="Norhart Supply Image 6"
        imageSix="/home/gallery/multifamily/norhart-supply-6.png"
        colorPalette={colorPalette}
        badge={false}
      />

      <GalleryImageLargeSection
        backgroundColorOne="#EFEFEF"
        backgroundColorTwo="#EFEFEF"
        icon={NorhartDarkCircleIcon}
        iconTitle="Inventory Control"
        title="Product Availability"
        subtitle="In order to reduce costs, we work to reduce the number of times products are handled and warehoused.  This means we generally don’t have products immediately available. Instead, we provide a continuous flow of products to a jobsite.  This works well on large multifamily construction projects that have a well-established flow and tact time.  We plan deliveries well in advance to ensure crews have the right product on the right day."
        imageTitleOne="Norhart Product Availability Image 1"
        imageOne="/home/gallery/inventory/norhart-supply-1.png"
        imageTitleTwo="Norhart Product Availability Image 2"
        imageTwo="/home/gallery/inventory/norhart-supply-2.png"
        imageTitleThree="Norhart Product Availability Image 3"
        imageThree="/home/gallery/inventory/norhart-supply-3.png"
        imageTitleFour="Norhart Product Availability Image 4"
        imageFour="/home/gallery/inventory/norhart-supply-4.png"
        imageTitleFive="Norhart Product Availability Image 5"
        imageFive="/home/gallery/inventory/norhart-supply-5.png"
        imageTitleSix="Norhart Product Availability Image 6"
        imageSix="/home/gallery/inventory/norhart-supply-6.png"
        imageTitleSeven="Norhart Product Availability Image 7"
        imageSeven="/home/gallery/inventory/norhart-supply-7.png"
        imageTitleEight="Norhart Product Availability Image 8"
        imageEight="/home/gallery/inventory/norhart-supply-8.png"
        imageTitleNine="Norhart Product Availability Image 9"
        imageNine="/home/gallery/inventory/norhart-supply-9.png"
        imageTitleTen="Norhart Product Availability Image 10"
        imageTen="/home/gallery/inventory/norhart-supply-10.png"
        imageTitleEleven="Norhart Product Availability Image 11"
        imageEleven="/home/gallery/inventory/norhart-supply-11.png"
        imageTitleTweleve="Norhart Product Availability Image 12"
        imageTweleve="/home/gallery/inventory/norhart-supply-12.png"
        colorPalette={colorPalette}
        badge={false}
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Lean Construction"
        title="Our Ideal Customers"
        starRating=""
        subTitle={`It’s worth noting that our system only works well with multifamily contractors who have built a strong lean construction system.  Your demand for supplies should be consistent and predictable.  If you see a lot of variability and unpredictability in your demand for supplies, other suppliers who can better provide immediate products may be a better fit.  If you have a strong construction process and are ready to lower your costs and improve the quality of your multifamily construction supplies, reach out to Norhart Supply!`}
        image="/home/norhart-customer-reviews.png"
        imageAlt="Norhart Supppy Customer Reviews"
        textColor={colorPalette.textColor}
      />

      <SixImageSection
        backgroundColorOne="#E5E8e9"
        backgroundColorTwo="#EFF2F4"
        colorPalette={colorPalette}
        fontTextColor="#365c70"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Supply"
        title="Products"
        subtitle="Norhart Supply continually strives for and provides innovative solutions for our customers and their multifamily construction needs.  Our inventory offers a wide range of products for all stages of construction."
        pillActive=""
        imageTitleOne="HVAC"
        imageOne="/hvac/1.jpg"
        imageOneAlt="HVAC"
        imageOnePill=""
        imageOneText="Norhart Supply provides the best in home heating and systems with top of the line HVAC systems, furnaces, air conditioners, and much more."
        buttonOneName ="View Products"
        buttonOneUrl="/hvac/"
        imageTitleTwo="Plumbing"
        imageTwo="/plumbing/1.jpg"
        imageTwoAlt="Plumbing"
        imageTwoPill=""
        imageTwoText="Norhart Supply offers a large inventory of plumbing supplies including plumbing fixtures in Minneapolis, MN. Contact us today for your project."
        buttonTwoName ="View Products"
        buttonTwoUrl="/plumbing/"
        imageTitleThree="Flooring"
        imageThree="/flooring/1.jpg"
        imageThreeAlt=""
        imageThreePill=""
        imageThreeText="Find all flooring styles including hardwood floors, carpeting, laminate, vinyl and tile flooring. Get the best flooring products from Norhart Supply"
        buttonThreeName ="View Products"
        buttonThreeUrl="/flooring/"
        imageTitleFour="Electrical"
        imageFour="/electrical/1.jpg"
        imageFourAlt=""
        imageFourPill=""
        imageFourText="We offer high-quality, professional electrical products and equipment. Supplies and solutions for all your multifamily contstruction projects."
        buttonFourName ="View Products"
        buttonFourUrl="/electrical/"
        imageTitleFive="Appliances"
        imageFive="/appliances/1.jpg"
        imageFiveAlt=""
        imageFivePill=""
        imageFiveText="We offer a full range of kitchen appliances from refrigerators, ovens and more! Products that bring ease and style to your kitchen."
        buttonFiveName ="View Products"
        buttonFiveUrl="/appliances/"
        imageTitleSix="Building Materials"
        imageSix="/building-materials/1.jpg"
        imageSixAlt=""
        imageSixPill=""
        imageSixText="The most common building materials used for different aspects of a home building are concrete, steel, wood, masonry, and stone."
        buttonSixName ="View Products"
        buttonSixUrl="/building-materials/"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentBottom}
        fontTextColor="#2f5265"
        titleTopLeft="High Quality"
        title="Curated Products"
        subtitle="We provide a curated list of products specifically tailored to the large, multifamily construction industry.  We intentionally limit selection of products to focus on opting for the right product.  In doing so, this allows us to offer high quality products at an attractive price point."
        imageTitle="Norhart Supply"
        image="/home/worker-jackhammer.jpeg"
        colorPalette={colorPalette}
        colSizeOne={6}
        colSizeTwo={6}
        bottomMargin={5}
        asoEffect="fade"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Our Company"
        title="About Norhart Supply"
        starRating=""
        subTitle={`Norhart Supply is a subsidiary of Norhart.  Norhart is one of Minnesota’s largest multifamily developers and operators.  They saw a need in the market for a low-cost high-quality supplier specifically tailored to the multifamily construction industry.  And thus, Norhart Supply was born.`}
        image="/home/norhart-suppy-construction-crews.png"
        imageAlt="Norhart Supply"
        textColor="#FFFFFF"
      />

    </Layout>
  )
}

/** export */
export default homePage
