import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  badgeTitle: string
  bottomMargin?: number
  buttonName: string
  buttonUrl?: string
  colorPalette: ColorPalette
  colSizeOne: number
  colSizeTwo: number
  fontTextColor: string
  image: string
  imageTitle: string
  subtitle: string
  title: string
  titleTopLeft: string
  imageSubtitle?: string
  imageTitleSubtitle?: string
  asoEffect: string
}

/** const */
const HeroRightSection: React.FC<Props> = (props) => {
  return (
    <section
      className={`container-fluid text-center pt-5 pb-${props.bottomMargin}`}
      style={{
        background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)`,
        color: `${props.colorPalette.sectionText}`,
      }}
    >
      <div className="container pl-2 pr-2">
        <div
          className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
          style={{ fontSize: "15px", color: `${props.fontTextColor}` }}
        >
          {props.titleTopLeft}
        </div>
        <hr
          className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
            color: `${props.fontTextColor}`,
          }}
        />
        <div className="contentHeroSpace hidden-xs"></div>

        <div className="row">
          <div
            className={`col-sm-${props.colSizeOne}`}
            style={{
              color: `${props.colorPalette.sectionText}`,
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <h3 className="contentHeroLeftTitle text-start m-0 p-0" style={{ color: `${props.fontTextColor}` }}>
              {props.title}
              <br />
              <span
                className="badge badge-secondary ms-0 mt-2 mb-4"
                style={{
                  fontSize: "0.9rem",
                  verticalAlign: "middle",
                  backgroundColor: `${props.colorPalette.buttonNewAlt}`,
                }}
              >
                {props.badgeTitle}
              </span>
            </h3>
            <h3
              className="contentHeroLeftSubTitle m-0 p-0"
              style={{
                color: `${props.fontTextColor}`,
                opacity: "0.7",
              }}
            >
              {props.subtitle} <br />
            </h3>

            {props.imageSubtitle && (
              <img
                className="pt-5 pb-0 ps-4 pe-4"
                data-aos={props.asoEffect}
                src={props.imageSubtitle}
                loading="lazy"
                decoding="async"
                alt={props.imageTitleSubtitle}
                width="550"
                height="60"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            )}

            {props.buttonUrl && (
              <div className="d-flex justify-content-left mt-5">
                <a
                  className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow"
                  href={props.buttonUrl}
                  target="_blank"
                  style={{
                    backgroundColor: "#ffffff",
                    border: "0px",
                    color: "#333333",
                  }}
                >
                  {props.buttonName}
                </a>
              </div>
            )}
          </div>

          <div className={`col-sm-${props.colSizeTwo} mt-5`}>
            <img
              className="pb-0 pl-0 pr-0 d-none d-sm-block"
              data-aos={props.asoEffect}
              src={props.image}
              loading="lazy"
              decoding="async"
              alt={props.imageTitle}
              width="900"
              height="400"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
            <img
              className="pb-0 pl-5 pr-5 ps-5 pe-5 d-block d-sm-none"
              src={props.image}
              loading="lazy"
              decoding="async"
              alt={props.imageTitle}
              width="900"
              height="400"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroRightSection
